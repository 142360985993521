body {
  margin: 0;
  padding: 0;
}


.app-logo {
  height: 8vmin;
  pointer-events: none;
}

img.ant-image-preview-img { display: inline-block; }

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.site-layout-sub-header-background {
  background: #fff;
  border-bottom: '1px solid #f7f7f7';
  padding: 0 10px;
}

.site-layout-background {
  background: #fff;
}

.site-page-header {
  padding: 0
}

